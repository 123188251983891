import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-3 px-8 lg:px-12`,
  xl: `py-3 px-12 lg:py-5 lg:px-16 text-lg`
};

const ButtonRadio = ({ captionNode, className = '', active, onClick }) => {
  return (
    <button
      type="button"
      className={`
        button-radio
        button-radio 
        ${className}
        flex
        flex-row
        flex-nowrap
        items-center
        w-max
        text-sm
        text-primary-lighter
        font-bold
        uppercase
        h-8 mx-2 
        px-2 
        border 
        border-solid 
        border-primary-lighter 
        rounded-3xl
        bg-white 
        // lg:h-10 
        lg:text-base
        hover:border-highlight
        hover:shadow-buttonRounded
      `}
      onClick={onClick}
    >
      <input className="button-radio-indicator" type="radio" checked={active} />
      <FormattedMessage id={captionNode}>
        {txt => <span className="ml-2" dangerouslySetInnerHTML={{ __html: txt }} />}
      </FormattedMessage>
    </button>
  );
};

ButtonRadio.propTypes = {
  captionNode: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

ButtonRadio.defaultProps = {
  className: '',
  onClick: undefined,
  active: false
};

export default ButtonRadio;
