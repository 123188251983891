import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import SEO from '../components/seo';
import Layout from '../components/layout/Layout';
import ButtonRadio from '../components/buttons/ButtonRadio';
import GtagOptInContext from '../components/layout/context';

export default () => {
  // const { gtagOptIn, setGtagOptIn } = React.useContext(GtagOptInContext);
  // console.log(gtagOptIn, setGtagOptIn);

  return (
    <Layout>
      <SEO title="BPT - Privacy Policy" />
      <GtagOptInContext>
        {({ gtagOptIn, setGtagOptIn }) => (
          <section className="privacy-policy py-10 lg:py-20">
            <div className="privacy-policy-text container mx-auto px-8 lg:flex">
              <FormattedMessage id="privacy_policy.text">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </div>
            <div
              id="privacy-policy-settings"
              className="privacy-policy-settings container mx-auto px-8"
            >
              <h2 className="privacy-policy-settings-title">
                <FormattedMessage id="privacy_policy.settings.title" />
              </h2>
              <p className="privacy-policy-settings-text">
                <FormattedMessage id="privacy_policy.settings.text" />
              </p>
              <div className="privacy-policy-settings-buttons mt-4 flex flex-row flex-nowrap">
                <ButtonRadio
                  className="privacy-policy-settings-button privacy-policy-settings-button--accept"
                  captionNode="privacy_policy.settings.accept"
                  active={gtagOptIn}
                  onClick={() => {
                    setGtagOptIn(true);
                  }}
                />
                <ButtonRadio
                  className="privacy-policy-settings-button privacy-policy-settings-button--decline"
                  captionNode="privacy_policy.settings.decline"
                  active={!gtagOptIn}
                  onClick={() => {
                    setGtagOptIn(false);
                  }}
                />
              </div>
            </div>
          </section>
        )}
      </GtagOptInContext>
    </Layout>
  );
};
